import React, { useState, useEffect } from "react";
import BUSD from "./assets/busd2.png";
import {
  pool1Address,
  pool2Address,
  pool3Address,
  BUSDAddress,
} from "./Config/Config.js";
import { ethers } from "ethers";
import InfoCard from "./components/InfoCard/InfoCard.js";
import ClaimCounter from "./components/ClaimCounter/ClaimCounter.js";
import calculator from "./assets/calculator.png";
import Swal from "sweetalert2";
import "./components/StakeCard/StakeCard.css";
import "@rainbow-me/rainbowkit/styles.css";
import {
  Chain,
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import { configureChains, createClient, WagmiConfig, useAccount } from "wagmi";

import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const binanceChain: Chain = {
  id: 56,
  name: "Binance Smart Chain",
  network: "binance",
  iconUrl:
    "https://seeklogo.com/images/B/binance-coin-bnb-logo-CD94CC6D31-seeklogo.com.png",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "Binance Coin",
    symbol: "BNB",
  },
  rpcUrls: {
    default: "https://bsc-dataseed.binance.org/",
  },
  blockExplorers: {
    default: { name: "Bscscan", url: "https://bscscan.com" },
    Bscscan: { name: "Bscscan", url: "https://bscscan.com" },
  },
  testnet: false,
};

const { provider, chains } = configureChains(
  [binanceChain],
  [jsonRpcProvider({ rpc: (chain) => ({ http: chain.rpcUrls.default }) })]
);

const { connectors } = getDefaultWallets({
  appName: "My RainbowKit App",
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

export const YourApp = () => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        mounted,
      }) => {
        return (
          <div
            {...(!mounted && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!mounted || !account || !chain) {
                return (
                  <button
                    className="px-4 py-2 text-slate-100 bg-[#20248E] hover:text-slate-100 rounded-md font-semibold text-xl"
                    onClick={openConnectModal}
                  >
                    Connect Wallet
                  </button>
                );
              }

              if (chain.unsupported) {
                return (
                  <button
                    className="text-white"
                    onClick={openChainModal}
                    type="button"
                  >
                    Wrong network
                  </button>
                );
              }
              return (
                <div className="text-green" style={{ display: "flex", gap: 5 }}>
                  <button
                    onClick={openAccountModal}
                    style={{ display: "flex", alignItems: "center" }}
                    type="button"
                  >
                    {chain.hasIcon && (
                      <div
                        style={{
                          background: chain.iconBackground,
                          width: 25,
                          height: 25,
                          borderRadius: 999,
                          overflow: "hidden",
                          marginRight: 4,
                        }}
                      >
                        {chain.iconUrl && (
                          <img
                            alt={chain.name ?? "Chain icon"}
                            src={chain.iconUrl}
                            style={{ width: 25, height: 25 }}
                          />
                        )}
                      </div>
                    )}
                    {/* {chain.name} */}
                  </button>

                  <button
                    onClick={openAccountModal}
                    type="button"
                    className="text-base text-white"
                  >
                    {chain.name} &nbsp;&nbsp;
                    {account.displayBalance ? `${account.displayBalance}` : ""}
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

const pool1ABI = require("./ABI/pool1abi.json");
const pool2ABI = require("./ABI/pool2abi.json");
const pool3ABI = require("./ABI/pool3abi.json");
const erc20abi = require("./ABI/erc20abi.json");

function App() {
  const [Walladdress, setAddress] = useState(null);
  const [stakeAmount, setStakeAmount] = useState("0");
  const [approved, setApproved] = useState(false);
  const [isActivePool1, setIsActivePool1] = useState(true);
  const [isActivePool2, setIsActivePool2] = useState(false);
  const [isActivePool3, setIsActivePool3] = useState(false);
  const [roi, setRoi] = useState("2.5% / month");
  const [annuallyRoi, setAnnuallyRoi] = useState("30% / year");

  const [userIndexArray, setUserIndexArray] = useState([]);
  const [userIndexArrayDeposit, setUserIndexArrayDeposit] = useState([]);
  const [userIndexArrayProfit, setUserIndexArrayProfit] = useState([]);
  const [userIndexArrayState, setUserIndexArrayState] = useState([]);

  const [userIndexArray2, setUserIndexArray2] = useState([]);
  const [userIndexArrayDeposit2, setUserIndexArrayDeposit2] = useState([]);
  const [userIndexArrayProfit2, setUserIndexArrayProfit2] = useState([]);
  const [userIndexArrayState2, setUserIndexArrayState2] = useState([]);
  const [userIndexArrayTimeStart2, setUserIndexArrayTimeStart2] = useState([]);
  const [userIndexArrayTimeEnd2, setUserIndexArrayTimeEnd2] = useState([]);

  const [userIndexArray3, setUserIndexArray3] = useState([]);
  const [userIndexArrayDeposit3, setUserIndexArrayDeposit3] = useState([]);
  const [userIndexArrayProfit3, setUserIndexArrayProfit3] = useState([]);
  const [userIndexArrayState3, setUserIndexArrayState3] = useState([]);

  const [totalLocked, setTotalLocked] = useState("0");
  const [allClaimableReward, setAllClaimableReward] = useState("0");
  const [totalInvested, setTotalInvested] = useState("0");

  const [totalTimeClaim1, setTotalTimeClaim1] = useState("0");
  const [totalTimeClaim2, setTotalTimeClaim2] = useState("0");
  const [totalTimeClaim3, setTotalTimeClaim3] = useState("0");

  const [totalLocked2, setTotalLocked2] = useState("0");
  const [allClaimableReward2, setAllClaimableReward2] = useState("0");
  const [totalInvested2, setTotalInvested2] = useState("0");

  const [totalLocked3, setTotalLocked3] = useState("0");
  const [allClaimableReward3, setAllClaimableReward3] = useState("0");
  const [totalInvested3, setTotalInvested3] = useState("0");

  const [totalLockedPool, setTotalLockedPool] = useState("0");

  const [busdPerDay, setBusdPerDay] = useState("0");
  const [busdPerHour, setBusdPerHour] = useState("0");

  const [busdPerDay2, setBusdPerDay2] = useState("0");
  const [busdPerHour2, setBusdPerHour2] = useState("0");

  const [busdPerDay3, setBusdPerDay3] = useState("0");
  const [busdPerHour3, setBusdPerHour3] = useState("0");

  const [userBalance, setUserBalance] = useState("0");

  const [referralAddress, setReferralAddress] = useState(
    "0x0000000000000000000000000000000000000000"
  );

  const [copyStatus, setCopyStatus] = useState(false);


  const [reflink, setRefLink] = useState(
    window.location.href + "/?ref=0x0000000000000000000000000000000000000000"
  );

  useEffect(() => {
    async function load() {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      let userAddress = signer.getAddress();

      let BUSD = new ethers.Contract(BUSDAddress, erc20abi, signer);
      let Pool1 = new ethers.Contract(pool1Address, pool1ABI, signer);
      let Pool2 = new ethers.Contract(pool2Address, pool2ABI, signer);
      let Pool3 = new ethers.Contract(pool3Address, pool3ABI, signer);

      const userbal = await BUSD.balanceOf(userAddress);
      setUserBalance(ethers.utils.formatEther(userbal.toString()));

      const totalDeposit = await Pool1.investors(userAddress);
      const totalDepositStr = ethers.utils.formatEther(
        totalDeposit.totalLocked.toString()
      );
      setTotalLocked(Number(totalDepositStr).toFixed(4));
      setBusdPerDay(Number(totalDepositStr) * 0.0008333333333333);
      setBusdPerHour(Number(totalDepositStr) * 0.00003472222222);
      const totalClaimable = await Pool1.getAllClaimableReward(userAddress);
      const totalClaimableStr = ethers.utils.formatEther(
        totalClaimable.toString()
      );
      setAllClaimableReward(Number(totalClaimableStr).toFixed(4));
      const totalInv = await Pool1.getTotalInvests();
      setTotalInvested(Number(ethers.utils.formatEther(totalInv)).toFixed(4));

      const totalDeposit2 = await Pool2.investors(userAddress);
      const totalDepositStr2 = ethers.utils.formatEther(
        totalDeposit2.totalLocked.toString()
      );
      setTotalLocked2(Number(totalDepositStr2).toFixed(4));
      setBusdPerDay2(Number(totalDepositStr2) * 0.00166666667);
      setBusdPerHour2(Number(totalDepositStr2) * 0.0000694444446);
      const totalClaimable2 = await Pool2.getAllClaimableReward(userAddress);
      const totalClaimableStr2 = ethers.utils.formatEther(
        totalClaimable2.toString()
      );
      setAllClaimableReward2(Number(totalClaimableStr2).toFixed(4));
      const totalInv2 = await Pool2.getTotalInvests();
      setTotalInvested2(Number(ethers.utils.formatEther(totalInv2)).toFixed(4));

      const totalDeposit3 = await Pool3.investors(userAddress);
      const totalDepositStr3 = ethers.utils.formatEther(
        totalDeposit3.totalLocked.toString()
      );
      setTotalLocked3(Number(totalDepositStr3).toFixed(4));
      setBusdPerDay3(Number(totalDepositStr3) * 0.004444444443);
      setBusdPerHour3(Number(totalDepositStr3) * 0.000185185185);
      const totalClaimable3 = await Pool3.getAllClaimableReward(userAddress);
      const totalClaimableStr3 = ethers.utils.formatEther(
        totalClaimable3.toString()
      );
      setAllClaimableReward3(Number(totalClaimableStr3).toFixed(4));
      const totalInv3 = await Pool3.getTotalInvests();
      setTotalInvested3(Number(ethers.utils.formatEther(totalInv3)).toFixed(4));

      let claimStart1 = await Pool1.claim(userAddress);
      setTotalTimeClaim1(claimStart1);

      let claimStart2 = await Pool2.claim(userAddress);
      setTotalTimeClaim2(claimStart2);

      let claimStart3 = await Pool3.claim(userAddress);
      setTotalTimeClaim3(claimStart3);

      let pool1 =
        Number(totalDepositStr) +
        Number(totalDepositStr2) +
        Number(totalDepositStr3);
      setTotalLockedPool(pool1);

      let userIDs = [];
      userIDs = await Pool1.getOwnedDeposits(userAddress);

      for (let i = 0; i < userIDs.length; i++) {
        setUserIndexArray((userIndexArray) => [
          ...userIndexArray,
          userIDs[i].toString(),
        ]);
        let infoArr = await Pool1.depositState(userIDs[i]);
        let invArr = await Pool1.getClaimableReward(userIDs[i]);
        setUserIndexArrayDeposit((userIndexArrayDeposit) => [
          ...userIndexArrayDeposit,
          ethers.utils.formatEther(infoArr.depositAmount.toString()),
        ]);
        setUserIndexArrayProfit((userIndexArrayProfit) => [
          ...userIndexArrayProfit,
          ethers.utils.formatEther(invArr.toString()),
        ]);
        setUserIndexArrayState((userIndexArrayState) => [
          ...userIndexArrayState,
          infoArr.state,
        ]);
      }

      let userIDs2 = [];
      userIDs2 = await Pool2.getOwnedDeposits(userAddress);

      for (let i = 0; i < userIDs2.length; i++) {
        setUserIndexArray2((userIndexArray2) => [
          ...userIndexArray2,
          userIDs2[i].toString(),
        ]);
        let infoArr2 = await Pool2.depositState(userIDs2[i]);
        let invArr2 = await Pool2.getClaimableReward(userIDs2[i]);
        setUserIndexArrayDeposit2((userIndexArrayDeposit2) => [
          ...userIndexArrayDeposit2,
          ethers.utils.formatEther(infoArr2.depositAmount.toString()),
        ]);
        setUserIndexArrayProfit2((userIndexArrayProfit2) => [
          ...userIndexArrayProfit2,
          ethers.utils.formatEther(invArr2.toString()),
        ]);
        setUserIndexArrayState2((userIndexArrayState2) => [
          ...userIndexArrayState2,
          infoArr2.state,
        ]);

        let start = infoArr2.depositAt.toString();
        setUserIndexArrayTimeStart2((userIndexArrayTimeStart2) => [
          ...userIndexArrayTimeStart2,
          Number(start),
        ]);

        // let startClaim = infoArr2.depositAt.toString();
        // setUserIndexArrayTimeStart2((userIndexArrayTimeStart2) => [
        //   ...userIndexArrayTimeStart2,
        //   Number(start),
        // ]);

        let timeEnd = infoArr2.depositAt.toString();
        let End = Number(timeEnd) + 86400 * 30;
        let end = parseInt(timeEnd) + 86400 * 30;
        let remaining = Number(End) - Date.now() / 1000;
        console.log(remaining);
        console.log(remaining / 86400);

        setUserIndexArrayTimeEnd2((userIndexArrayTimeEnd2) => [
          ...userIndexArrayTimeEnd2,
          end,
        ]);
      }

      let userIDs3 = [];
      userIDs3 = await Pool3.getOwnedDeposits(userAddress);

      for (let i = 0; i < userIDs3.length; i++) {
        setUserIndexArray3((userIndexArray3) => [
          ...userIndexArray3,
          userIDs3[i].toString(),
        ]);
        let infoArr3 = await Pool3.depositState(userIDs3[i]);
        let invArr3 = await Pool3.getClaimableReward(userIDs3[i]);
        setUserIndexArrayDeposit3((userIndexArrayDeposit3) => [
          ...userIndexArrayDeposit3,
          ethers.utils.formatEther(infoArr3.depositAmount.toString()),
        ]);
        setUserIndexArrayProfit3((userIndexArrayProfit3) => [
          ...userIndexArrayProfit3,
          ethers.utils.formatEther(invArr3.toString()),
        ]);
        setUserIndexArrayState3((userIndexArrayState3) => [
          ...userIndexArrayState3,
          infoArr3.state,
        ]);
      }
    }

    load();
  }, []);

  function Addy() {
    const { address } = useAccount();
    // console.log("Connected", { address });
    setAddress(address);
    const url =
      window.location.protocol +
      "//" +
      window.location.host +
      "/?ref=" +
      Walladdress;
    console.log(url);
    setRefLink(url);
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const ref = queryParams.get("ref");
    if (ref == null) {
      setReferralAddress("0x0000000000000000000000000000000000000000");
    }else{
      setReferralAddress(ref);
    }
  },[]);


  // const waitForTransaction = useWaitForTransaction({
  //   hash: txhash,
  // })

  // const isApproved = waitForTransaction;

  const togglePool1 = () => {
    setIsActivePool1(!isActivePool1);
    setIsActivePool2(false);
    setIsActivePool3(false);
    setRoi("2.5% / month");
    setAnnuallyRoi("30% / year");
  };

  const togglePool2 = () => {
    setIsActivePool2(!isActivePool2);
    setIsActivePool1(false);
    setIsActivePool3(false);
    setRoi("5% / month");
    setAnnuallyRoi("60% / year");
  };

  const togglePool3 = () => {
    setIsActivePool3(!isActivePool3);
    setIsActivePool1(false);
    setIsActivePool2(false);
    setRoi("13.33% / month");
    setAnnuallyRoi("160% / year");
  };

  const handleAmount = (event) => {
    var val = event.target.value;
    setStakeAmount(ethers.utils.parseEther(val).toString());
  };

  const handleApproval = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    let BUSD = new ethers.Contract(BUSDAddress, erc20abi, signer);

    if (isActivePool1) {
      await BUSD.approve(pool1Address, stakeAmount);
    } else if (isActivePool2) {
      await BUSD.approve(pool2Address, stakeAmount);
    } else if (isActivePool3) {
      await BUSD.approve(pool3Address, stakeAmount);
    }

    setTimeout(() => {
        setApproved(true)
    }, 10000);

  };

  const handleDeposit = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    let Pool1 = new ethers.Contract(pool1Address, pool1ABI, signer);

    let Pool2 = new ethers.Contract(pool2Address, pool2ABI, signer);

    let Pool3 = new ethers.Contract(pool3Address, pool3ABI, signer);

    
    console.log("Final Ref" + referralAddress);

    if (isActivePool1) {
      try {
        await Pool1.deposit(stakeAmount, referralAddress);
      } catch (err) {
        let message = err.reason;
        console.log(message);
        Swal.fire({
          title: "Error!",
          text: message,
          icon: "error",
          customClass: "swal-wide",
        });
      }

      Pool1.on("depositComplete", (spender) => {
        console.log(spender);
        async function refresh() {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          let userAddress = signer.getAddress();

          let Pool1 = new ethers.Contract(pool1Address, pool1ABI, signer);

          let userIDs = [];
          userIDs = await Pool1.getOwnedDeposits(userAddress);

          setUserIndexArray((userIndexArray) => [
            ...userIndexArray,
            userIDs[userIDs.length - 1].toString(),
          ]);
          let infoArr = await Pool1.depositState(userIDs[userIDs.length - 1]);
          let invArr = await Pool1.getClaimableReward(
            userIDs[userIDs.length - 1]
          );
          setUserIndexArrayDeposit((userIndexArrayDeposit) => [
            ...userIndexArrayDeposit,
            ethers.utils.formatEther(infoArr.depositAmount.toString()),
          ]);
          setUserIndexArrayProfit((userIndexArrayProfit) => [
            ...userIndexArrayProfit,
            ethers.utils.formatEther(invArr.toString()),
          ]);
          setUserIndexArrayState((userIndexArrayState) => [
            ...userIndexArrayState,
            infoArr.state,
          ]);

          const totalDeposit = await Pool1.investors(userAddress);
          const totalDepositStr = ethers.utils.formatEther(
            totalDeposit.totalLocked.toString()
          );
          setTotalLocked(Number(totalDepositStr).toFixed(4));
          setBusdPerDay(Number(totalDepositStr) * 0.0008333333333333);
          setBusdPerHour(Number(totalDepositStr) * 0.00003472222222);
          const totalClaimable = await Pool1.getAllClaimableReward(userAddress);
          const totalClaimableStr = ethers.utils.formatEther(
            totalClaimable.toString()
          );
          setAllClaimableReward(Number(totalClaimableStr).toFixed(4));
          const totalInv = await Pool1.getTotalInvests();
          setTotalInvested(
            Number(ethers.utils.formatEther(totalInv)).toFixed(4)
          );

          setApproved(false);
        }
        refresh();
      });
    } else if (isActivePool2) {
      try {
        await Pool2.deposit(stakeAmount, referralAddress);
      } catch (err) {
        let message = err.reason;
        console.log(message);
        Swal.fire({
          title: "Error!",
          text: message,
          icon: "error",
          customClass: "swal-wide",
        });
      }
      Pool2.on("depositComplete", (spender) => {
        console.log(spender);
        async function refresh() {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          let userAddress = signer.getAddress();

          let Pool2 = new ethers.Contract(pool2Address, pool2ABI, signer);
          let userIDs2 = [];
          userIDs2 = await Pool2.getOwnedDeposits(userAddress);
          console.log(userIDs2.length);

          setUserIndexArray2((userIndexArray2) => [
            ...userIndexArray2,
            userIDs2[userIDs2.length - 1].toString(),
          ]);
          let infoArr2 = await Pool2.depositState(
            userIDs2[userIDs2.length - 1]
          );
          let invArr2 = await Pool2.getClaimableReward(
            userIDs2[userIDs2.length - 1]
          );
          setUserIndexArrayDeposit2((userIndexArrayDeposit2) => [
            ...userIndexArrayDeposit2,
            ethers.utils.formatEther(infoArr2.depositAmount.toString()),
          ]);
          setUserIndexArrayProfit2((userIndexArrayProfit2) => [
            ...userIndexArrayProfit2,
            ethers.utils.formatEther(invArr2.toString()),
          ]);
          setUserIndexArrayState2((userIndexArrayState2) => [
            ...userIndexArrayState2,
            infoArr2.state,
          ]);

          let start = infoArr2.depositAt.toString();
          setUserIndexArrayTimeStart2((userIndexArrayTimeStart2) => [
            ...userIndexArrayTimeStart2,
            Number(start),
          ]);

          let timeEnd = infoArr2.depositAt.toString();
          let end = Number(timeEnd) + 86400 * 30;

          setUserIndexArrayTimeEnd2((userIndexArrayTimeEnd2) => [
            ...userIndexArrayTimeEnd2,
            end,
          ]);

          const totalDeposit2 = await Pool2.investors(userAddress);
          const totalDepositStr2 = ethers.utils.formatEther(
            totalDeposit2.totalLocked.toString()
          );
          setTotalLocked2(Number(totalDepositStr2).toFixed(4));
          setBusdPerDay2(Number(totalDepositStr2) * 0.00166666667);
          setBusdPerHour2(Number(totalDepositStr2) * 0.0000694444446);
          const totalClaimable2 = await Pool2.getAllClaimableReward(
            userAddress
          );
          const totalClaimableStr2 = ethers.utils.formatEther(
            totalClaimable2.toString()
          );
          setAllClaimableReward2(Number(totalClaimableStr2).toFixed(4));
          const totalInv2 = await Pool2.getTotalInvests();
          setTotalInvested2(
            Number(ethers.utils.formatEther(totalInv2)).toFixed(4)
          );
          setApproved(false);
        }
        refresh();
      });
    }
    if (isActivePool3) {
      try {
        await Pool3.deposit(stakeAmount, referralAddress);
      } catch (err) {
        let message = err.reason;
        console.log(message);
        Swal.fire({
          title: "Error!",
          text: message,
          icon: "error",
          customClass: "swal-wide",
        });
      }
      Pool3.on("depositComplete", (spender) => {
        console.log(spender);
        async function refresh() {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          let userAddress = signer.getAddress();
          let Pool3 = new ethers.Contract(pool3Address, pool3ABI, signer);

          let userIDs3 = [];
          userIDs3 = await Pool3.getOwnedDeposits(userAddress);
          console.log(userIDs3.length);

          setUserIndexArray3((userIndexArray3) => [
            ...userIndexArray3,
            userIDs3[userIDs3.length - 1].toString(),
          ]);
          let infoArr3 = await Pool3.depositState(
            userIDs3[userIDs3.length - 1]
          );
          let invArr3 = await Pool3.getClaimableReward(
            userIDs3[userIDs3.length - 1]
          );
          setUserIndexArrayDeposit3((userIndexArrayDeposit3) => [
            ...userIndexArrayDeposit3,
            ethers.utils.formatEther(infoArr3.depositAmount.toString()),
          ]);
          setUserIndexArrayProfit3((userIndexArrayProfit3) => [
            ...userIndexArrayProfit3,
            ethers.utils.formatEther(invArr3.toString()),
          ]);
          setUserIndexArrayState3((userIndexArrayState3) => [
            ...userIndexArrayState3,
            infoArr3.state,
          ]);

          setApproved(false);
        }
        refresh();
      });
    }
  };

  const handleClaimAllRewards = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    let Pool1 = new ethers.Contract(pool1Address, pool1ABI, signer);

    let Pool2 = new ethers.Contract(pool2Address, pool2ABI, signer);

    let Pool3 = new ethers.Contract(pool3Address, pool3ABI, signer);

    if (isActivePool1) {
      try {
        await Pool1.claimAllReward();
      } catch (err) {
        let message = err.reason;
        console.log(message);
        Swal.fire({
          title: "Error!",
          text: message,
          icon: "error",
          customClass: "swal-wide",
        });
      }
    } else if (isActivePool2) {
      try {
        await Pool2.claimAllReward();
      } catch (err) {
        let message = err.reason;
        console.log(message);
        Swal.fire({
          title: "Error!",
          text: message,
          icon: "error",
          customClass: "swal-wide",
        });
      }
    } else if (isActivePool3) {
      try {
        await Pool3.claimAllReward();
      } catch (err) {
        let message = err.reason;
        console.log(message);
        Swal.fire({
          title: "Error!",
          text: message,
          icon: "error",
          customClass: "swal-wide",
        });
      }
    }
  };

  const handleWithdrawAllCapital = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    let Pool1 = new ethers.Contract(pool1Address, pool1ABI, signer);

    let Pool2 = new ethers.Contract(pool2Address, pool2ABI, signer);

    let Pool3 = new ethers.Contract(pool3Address, pool3ABI, signer);

    if (isActivePool1) {
      try {
        await Pool1.withdrawAllCapital();
      } catch (err) {
        let message = err.reason;
        console.log(message);
        Swal.fire({
          title: "Error!",
          text: message,
          icon: "error",
          customClass: "swal-wide",
        });
      }
    } else if (isActivePool2) {
      try {
        await Pool2.withdrawAllCapital();
      } catch (err) {
        let message = err.reason;
        console.log(message);
        Swal.fire({
          title: "Error!",
          text: message,
          icon: "error",
          customClass: "swal-wide",
        });
      }
    } else if (isActivePool3) {
      try {
        await Pool3.withdrawAllCapital();
      } catch (err) {
        let message = err.reason;
        console.log(message);
        Swal.fire({
          title: "Error!",
          text: "Withdraw is not allowed",
          icon: "error",
          customClass: "swal-wide",
        });
      }
    }
  };

  return (
    <>
      <div className="mx-5 md:mx-20 desktop:px-6 py-5">
        <div className="flex flex-row justify-between ">
          <div className="my-auto text-2xl">
            <span className="text-6xl">Jhoomi</span>
          </div>
          <div className="flex justify-center mt-2">
            <WagmiConfig client={wagmiClient}>
              <RainbowKitProvider
                chains={chains}
                theme={darkTheme({
                  accentColor: "#20248E",
                  accentColorForeground: "white",
                  borderRadius: "small",
                  fontStack: "system",
                  overlayBlur: "small",
                })}
              >
                <YourApp />
                {/* <Profile /> */}
                <Addy />
              </RainbowKitProvider>
            </WagmiConfig>
          </div>
        </div>
      </div>
      <div className="desktop:grid grid-cols-3 gap-4 mobile:grid-cols-12 mt-16">
        <div className="p-8 text-white">
          <p className="text-5xl mx-2 mt-2 mb-8 text-[#5572AB]">Stake Crypto</p>
          <div
            className="bg-[#3A301B] border-2 border-[#966519] center p-5"
            style={{ width: "15vh", height: "15vh", borderRadius: "15px" }}
          >
            <img
              className="mx-auto my-auto"
              src={BUSD}
              alt="BUSD-coin"
              style={{ width: "8vh" }}
            />
            <p className="text-[#966519] text-center font-semibold pt-1">
              BUSD
            </p>
          </div>

          <div
            className="flex mt-16 bg-[#3A301B] poolBorder"
            style={{ borderRadius: "10px" }}
          >
            <button
              className={
                isActivePool1 ? "flex-grow bg-[#353AB6] py-2" : "flex-grow py-2"
              }
              style={{ borderRadius: "10px" }}
              onClick={togglePool1}
            >
              Pool 1
            </button>
            <button
              className={
                isActivePool2 ? "flex-grow bg-[#353AB6] py-2" : "flex-grow py-2"
              }
              onClick={togglePool2}
            >
              Pool 2
            </button>
            <button
              className={
                isActivePool3 ? "flex-grow bg-[#353AB6] py-2" : "flex-grow py-2"
              }
              style={{ borderRadius: "10px" }}
              onClick={togglePool3}
            >
              Pool 3
            </button>
          </div>

          <div className="bg-[#111218] mt-16 p-10 shadow">
            <span className="text-xl">Stake amount</span>
            <span className="float-right">
              {Number(userBalance).toFixed(4)} BUSD
            </span>
            <br />

            <div className="container pt-5">
              <input
                className="input mt-8 bg-transparent border-2 border-[#575A7D] w-full pr-5"
                placeholder="Enter BUSD Amount"
                type="number"
                style={{ borderRadius: "20px" }}
                onChange={(event) => handleAmount(event)}
              />
              <img
                src="https://cryptologos.cc/logos/binance-usd-busd-logo.png?v=023"
                className="image"
                alt="busd"
              />
            </div>

            <p className="py-5 text-xl">You will generate:</p>
            <p>
              <span className="text-[#5572AB]">Profits per month</span>
              <span className="float-right">{roi}</span>
            </p>
            <br />
            <span className="text-[#5572AB]">Total Annually</span>
            <span className="float-right">{annuallyRoi}</span>
            <br />
            <br />

            <div>
              <span className="text-[#5572AB] px-auto py-auto">
                Time until rewards unlocked
              </span>
              {/* <span className="float-right">{annuallyRoi}</span> */}
              <div className="float-right mx-auto my-auto">
                {isActivePool1 ? (
                  <ClaimCounter start={totalTimeClaim1} />
                ) : null}

                {isActivePool2 ? (
                  <ClaimCounter start={totalTimeClaim2} />
                ) : null}

                {isActivePool3 ? (
                  <ClaimCounter start={totalTimeClaim3} />
                ) : null}
              </div>
            </div>

            <div
              className="flex bg-[#353AB6] py-2 font-semibold mt-8 mb-3"
              style={{ borderRadius: "18px" }}
            >
              {approved ? (
                <button className="flex-grow" onClick={handleDeposit}>
                  Deposit
                </button>
              ) : (
                <button className="flex-grow" onClick={handleApproval}>
                  Approve
                </button>
              )}
            </div>

            <div
              className="flex border-2 border-[#575A7D] py-2 my-3"
              style={{ borderRadius: "18px" }}
            >
              <button
                className="flex-grow text-[#20248E] font-semibold"
                onClick={handleClaimAllRewards}
              >
                Withdraw Rewards
              </button>
            </div>
            {isActivePool3 ? null : (
              <div
                className="flex border-2 border-[#575A7D] py-2 my-3"
                style={{ borderRadius: "18px" }}
              >
                <button
                  className="flex-grow text-[#20248E] font-semibold"
                  onClick={handleWithdrawAllCapital}
                >
                  Withdraw All Capital
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="col-span-2 p-8">
          <div className="pl-5">
            <img
              className="inline-block"
              src={calculator}
              alt="calculator"
              style={{ width: "10vh" }}
            />
            <span className="text-3xl font-bold pl-5">Calculator</span>
            <p className="mt-28 text-2xl">
              Total User Investment : {Number(totalLockedPool).toFixed(4)} BUSD
            </p>
            <div className="desktop:grid grid-cols-2 gap-24 mt-16">
              <div>
                <p className="text-2xl mb-7 font-bold text-[rgb(148 163 184)]">
                  Deposit
                </p>
                <div className="mb-4">
                  <span className="text-xl mb-8 text-[#5572AB]">
                    Total Deposited
                  </span>
                  {isActivePool1 ? (
                    <span className="float-right">{totalLocked} BUSD</span>
                  ) : null}
                  {isActivePool2 ? (
                    <span className="float-right">{totalLocked2} BUSD</span>
                  ) : null}
                  {isActivePool3 ? (
                    <span className="float-right">{totalLocked3} BUSD</span>
                  ) : null}
                  <br />
                </div>

                <p className="text-2xl mt-8 mb-6 font-bold">Withdraw Rewards</p>
                <div className="mb-4">
                  <span className="text-xl text-[#5572AB]">
                    All Claimable Rewards
                  </span>
                  {isActivePool1 ? (
                    <span className="float-right">
                      {allClaimableReward} BUSD
                    </span>
                  ) : null}
                  {isActivePool2 ? (
                    <span className="float-right">
                      {allClaimableReward2} BUSD
                    </span>
                  ) : null}
                  {isActivePool3 ? (
                    <span className="float-right">
                      {allClaimableReward3} BUSD
                    </span>
                  ) : null}
                  <br />
                </div>

                <p className="text-xl mt-16">
                  <span className="font-bold">Total Invested:</span>{" "}
                  {isActivePool1 ? (
                    <span className="pl-2">{totalInvested} BUSD</span>
                  ) : null}
                  {isActivePool2 ? (
                    <span className="pl-2">{totalInvested2} BUSD</span>
                  ) : null}
                  {isActivePool3 ? (
                    <span className="pl-2">{totalInvested3} BUSD</span>
                  ) : null}
                </p>
              </div>

              {isActivePool1 ? (
                <div className="text-[#5572AB]">
                  <p className="text-2xl mb-4 mobilemarg text-white">
                    Withdraw Capital
                  </p>
                  <div className="my-8">
                    <span>BUSD per Day </span>
                    <span className="float-right">
                      {Number(busdPerDay).toFixed(4)} BUSD
                    </span>{" "}
                    <br />
                  </div>

                  <p className="text-2xl mb-4 mobilemarg text-white">
                    Referral
                  </p>

                  {/* Referral Link */}
                  {totalLockedPool > 0 ? (
                    <div className="mt-10 ">
                      <div className="mb-4 flex  rounded-md">
                        <span className="text-[#5572AB] font-bold pt-1">
                          Referral:{" "}
                        </span>
                        <div className="bg-[#3A301B] rounded-xl ml-3 flex">
                          <input
                            value={reflink}
                            className="pt-1 bg-[#3A301B] text-white rounded-xl desktop:w-60 mobile:w-full outline-none text-slate px-5"
                          />
                          <button
                            className="text-white bg-[#966519] px-2 rounded-lg float-right my-1"
                            onClick={() => {
                              window.navigator.clipboard.writeText(reflink);
                              setCopyStatus(true);
                            }}
                          >
                            {copyStatus ? "Copied!" : "COPY"}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <p>
                        Please deposit BUSD into the pool to get your referral
                        link.
                      </p>{" "}
                    </>
                  )}
                </div>
              ) : null}

              {isActivePool2 ? (
                <div className="text-[#5572AB]">
                  <p className="text-2xl mb-4 mobilemarg text-white">
                    Withdraw Capital
                  </p>
                  <div className="my-8">
                    <span>BUSD per Day </span>
                    <span className="float-right">
                      {Number(busdPerDay2).toFixed(4)} BUSD
                    </span>{" "}
                    <br />
                  </div>
                  <p className="text-2xl mb-4 mobilemarg text-white">
                    Referral
                  </p>
                  {/* Referral Link */}
                  {totalLockedPool > 0 ? (
                    <div className="mt-10 ">
                      <div className="mb-4 flex  rounded-md">
                        <span className="text-[#5572AB] font-bold pt-1">
                          Referral:{" "}
                        </span>
                        <div className="bg-[#3A301B] rounded-xl ml-3 flex">
                          <input
                            value={reflink}
                            className="pt-1 bg-[#3A301B] text-white rounded-xl desktop:w-60 mobile:w-full outline-none text-slate px-5"
                          />
                          <button
                            className="text-white bg-[#966519] px-2 rounded-lg float-right my-1"
                            onClick={() => {
                              window.navigator.clipboard.writeText(reflink);
                              setCopyStatus(true);
                            }}
                          >
                            {copyStatus ? "Copied!" : "COPY"}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <p>
                        Please deposit BUSD into the pool to get your referral
                        link.
                      </p>{" "}
                    </>
                  )}
                </div>
              ) : null}

              {isActivePool3 ? (
                <div className="text-[#5572AB]">
                  <p className="text-2xl mb-4 mobilemarg text-white">
                    Withdraw Capital
                  </p>
                  <div className="my-8">
                    <span>BUSD per Day </span>
                    <span className="float-right">
                      {Number(busdPerDay3).toFixed(4)} BUSD
                    </span>{" "}
                    <br />
                  </div>

                  <p className="text-2xl mb-4 mobilemarg text-white">
                    Referral
                  </p>

                  {/* Referral Link */}
                  {totalLockedPool > 0 ? (
                    <div className="mt-10 ">
                      <div className="mb-4 flex  rounded-md">
                        <span className="text-[#5572AB] font-bold pt-1">
                          Referral:{" "}
                        </span>
                        <div className="bg-[#3A301B] rounded-xl ml-3 flex">
                          <input
                            value={reflink}
                            className="pt-1 bg-[#3A301B] text-white rounded-xl desktop:w-60 mobile:w-full outline-none text-slate px-5"
                          />
                          <button
                            className="text-white bg-[#966519] px-2 rounded-lg float-right my-1"
                            onClick={() => {
                              window.navigator.clipboard.writeText(reflink);
                              setCopyStatus(true);
                            }}
                          >
                            {copyStatus ? "Copied!" : "COPY"}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <p>
                        Please deposit BUSD into the pool to get your referral
                        link.
                      </p>{" "}
                    </>
                  )}
                </div>
              ) : null}

              {!isActivePool1 && !isActivePool2 && !isActivePool3 ? (
                <div className="text-[#5572AB]">
                  <p className="text-2xl mb-4 mobilemarg text-white">
                    Withdraw Capital
                  </p>
                  <div className="my-8">
                    <span>BUSD per Day </span>
                    <span className="float-right">
                      {Number(busdPerDay3).toFixed(4)} BUSD
                    </span>{" "}
                    <br />
                  </div>
                </div>
              ) : null}
            </div>

            <div>
              <p className="mt-14 mb-5 text-3xl">
                Staking Info{" "}
                <img
                  className="inline-block mx-auto my-auto font-semibold"
                  src={BUSD}
                  alt="calculator"
                  style={{ width: "2.5vh" }}
                />{" "}
              </p>

              <div className="desktop:grid grid-cols-2">
                {isActivePool1
                  ? userIndexArray.map((data, index) => {
                      return userIndexArrayState[index] ? (
                        <InfoCard
                          key={index}
                          id={data}
                          deposit={userIndexArrayDeposit[index]}
                          index={data}
                          profit={userIndexArrayProfit[index]}
                          pool={1}
                        />
                      ) : null;
                    })
                  : null}

                {isActivePool2
                  ? userIndexArray2.map((data, index) => {
                      return userIndexArrayState2[index] ? (
                        <InfoCard
                          key={index}
                          id={data}
                          deposit={userIndexArrayDeposit2[index]}
                          index={data}
                          profit={userIndexArrayProfit2[index]}
                          start={userIndexArrayTimeStart2[index]}
                          end={userIndexArrayTimeEnd2[index] + 86400}
                          pool={2}
                        />
                      ) : null;
                    })
                  : null}

                {isActivePool3
                  ? userIndexArray3.map((data, index) => {
                      return userIndexArrayState3[index] ? (
                        <InfoCard
                          key={index}
                          id={data}
                          deposit={userIndexArrayDeposit3[index]}
                          index={data}
                          profit={userIndexArrayProfit3[index]}
                          pool={3}
                        />
                      ) : null;
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
