import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {
  pool1Address,
  pool2Address,
  pool3Address,
  BUSDAddress,
} from "../../Config/Config.js";
import { ethers } from "ethers";
import Swal from 'sweetalert2'
import "./InfoCard.css";

const pool1ABI = require("../../ABI/pool1abi.json");
const pool2ABI = require("../../ABI/pool1abi.json");
const pool3ABI = require("../../ABI/pool1abi.json");
const erc20abi = require("../../ABI/erc20abi.json");
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 50,
  strokeWidth: 4
};

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div className="time">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

let getTimeDays = (time) => (time / daySeconds) | 0;

function infoCard(props) {

  let stratTime;
  let endTime;

  if(props.pool == 2){
    stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
    endTime = props.end; // use UNIX timestamp in seconds
  }
  else{
    stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
    endTime = Date.now() / 1000; // use UNIX timestamp in seconds
  }
  
 


  let remainingTime = endTime - stratTime;
  let days = Math.ceil(remainingTime / daySeconds);
  let daysDuration = days * daySeconds;

  const handleWithdrawById = async() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    let Pool1 = new ethers.Contract(pool1Address, pool1ABI, signer);
    let Pool2 = new ethers.Contract(pool2Address, pool2ABI, signer);
    let Pool3 = new ethers.Contract(pool3Address, pool3ABI, signer);
    console.log(days);

    if(props.pool == 1){
      try{
        await Pool1.withdrawCapital(props.index)
      }catch(err){
        let message = err.reason;
        console.log(message);
        Swal.fire({
          title: 'Error!',
          text: message,
          icon: 'error',
          customClass: 'swal-wide',
        })
      }
    }
    else if(props.pool == 2){
      try{
        await Pool2.withdrawCapital(props.index)
      }catch(err){
        let message = err.reason;
        console.log(message);
        Swal.fire({
          title: 'Error!',
          text: message,
          icon: 'error',
          customClass: 'swal-wide',
        })
      }
    }
    else if(props.pool == 3){
      try{
        await Pool3.withdrawCapital(props.index);
      }catch(err){
        let message = err.reason;
        console.log(message);
        Swal.fire({
          title: 'Error!',
          text: 'Withdraw is not allowed',
          icon: 'error',
          customClass: 'swal-wide',
        })
      }
    }
    
  };

    return(

        <div className="px-10 py-6 bg-[#111218] my-10 shadow" style={{width:"35vh", borderRadius:"10px"}}>
        <p className="mb-5">#{props.id}</p>
        <span> Deposit </span>
        <span className="float-right"> {Number(props.deposit).toFixed(4)} </span> <br/>
        <span> Profit </span>
        <span className="float-right"> {Number(props.profit).toFixed(4)} </span> <br/>
        <div className="App">
      {props.pool == 3 ? (null) : (
        <>
        <button className="float-right my-2 px-5 py-2 bg-[#20248E]" style={{borderRadius:"5px", fontFamily:"feature"}} onClick={handleWithdrawById}> Withdraw </button>
        <CountdownCircleTimer
        {...timerProps}
        colors="#7E2E84"
        duration={daysDuration}
        initialRemainingTime={remainingTime}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("", getTimeDays(daysDuration - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      </>
      )}
      
    </div>
        </div>

    );
}

export default infoCard;