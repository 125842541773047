import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Auth0Provider } from "./react-auth0-spa";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import config from "./auth_config.json";


const queryParams = new URLSearchParams(window.location.search);
const ref = queryParams.get("ref");
console.log("ref" + ref);
let url = window.location.protocol + "//" + window.location.host;
if(ref != null){
    url = window.location.protocol + "//" + window.location.host + "/?ref=" + ref;
    console.log(url);
}



// A function that routes the user to the right place after login
const onRedirectCallback = (appState) => {
    window.history.replaceState({}, document.title, url);
};

// arg 1: React element, arg2: container DOM element
// Mounts arg1 as a child in the DOM container (arg2)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Auth0Provider domain={config.domain} client_id={config.clientId} redirect_uri={url} onRedirectCallback={onRedirectCallback}>
        <App />
    </Auth0Provider>,

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
