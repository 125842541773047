import "./Footer.css";

function Footer () {
    return(

        <div className="text-right px-10 text-base" id="footer">
        <a href="/#/privacy">Privacy Policy</a>
        </div>
    );
}

export default Footer;