import React, { useState, useEffect } from "react";
import Home from "./Home"
import Footer from "./Footer"
import './App.css';
import Privacy from './Privacy.js';
import { HashRouter, Switch, Route } from "react-router-dom";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// import { Link } from "react-router-dom";
import NavBarFn from "./NavBarFn";
import close from "./assets/close.png"

// import "bootstrap/dist/css/bootstrap.min.css";

// React Custom Hook to get the Auth0 Context
import { useContextAuth0 } from "./react-auth0-spa";

export default function App() {
    // This custom hook calls useContext() to get access to global props/methods related to our Authentication capability
    // Then use object destructuring to select item I need from the provided context
    const { logout, loading } = useContextAuth0();

    // Create our "state" using React State Hook (i.e. "useState")
    // This creates states variables inside a (otherwise stateless) React Functional Component
    // React will remember these "state" value between renders
    const [modalShow, setModalShow] = useState(false);
    const [modalMessage, setModalMessage] = useState("Modal Message Placeholder");
    const [modalTitle, setModalTitle] = useState("Modal Title Placeholder");
    const [forceLogout, setForceLogout] = useState(false);
    const [referralAddress, setReferralAddress] = useState(null);
    // React Hook, useEffect() runs after first render, and after every re-render (DOM update)
    // Essentially a "side effect" sort of like componentDidMount, componentDidUpdate, and componentWillUnmount combined
    // 2nd arg (array) is empty, so this fn is called every time it's re-rendered
    useEffect(() => {
        // console.log("useEffect()");
        // setForceLogout(true);

        const urlParams = new URLSearchParams(window.location.search);
        // console.log("urlParams:", urlParams);

        // Check if we got "error" within query params
        if (urlParams.get("error")) {
            console.error("Error from Auth0");
            console.log(`All URL Query Params: ${window.location.search}`);
            const keys = urlParams.keys();
            for (const key of keys) {
                console.log(`   key:${key}, value:${urlParams.get(key)}`);
            }
            // Set title and message for Modal
            setModalTitle(urlParams.get("error"));
            // setModalMessage(urlParams.get("error_description"));
            setModalMessage(<div className="card py-5 px-4"><p className="text-5xl pb-5">Welcome To JHOOMI</p>
            <p style={{fontSize:"1.2rem"}}>Before we can approve your access to our private group, we require you to first complete these steps to ensure that you understand this investment opportunity. </p>
            

            <div className="text-left desktop:px-5 mobile:px-5 desktop:pt-16 mobilee:pt-5 text-base">
            <p className="py-3">⦁ Please download and create a Discord profile you MUST have a profile picture to see our server.</p>
            <p className="py-3">⦁ Join community Discord using this link: <a className="hover:text-[#20248E]" href="https://discord.gg/umAktv3gES">https://discord.gg/umAktv3gES</a></p>
            <p className="py-3">⦁ Verify yourself</p>
            <p className="py-3">⦁ Read over the F.A.Q, Rules, and Documents to fully understand our fund.</p>
            <p className="py-3">⦁ Check our tutorials to learn how to invest in our fund.</p>
            <p className="py-3">⦁ Finally, fill out an application form and you must know the person that referred you.</p>
            <p className="py-3">⦁ Once the application has been done you will be authorized to make the initial deposit into fund pool 3.</p>
            <p className="py-3">⦁ To be fully invested in our Fund you must make the initial deposit and you will become a MEMBER in our discord and have full access to our full resources and library.</p>
            </div></div>);
            // Turn on modal so it pops up
            setModalShow(true);

            // Clear all the query params from the URL
            window.history.replaceState({}, document.title, window.location.pathname);

            // As a courtesy, truly logout the user since they got error, will allow them to login again
            // Otherwise, it seems to keep using the same cached login, and giving the same error
            // logout();
            setForceLogout(true);
        }
    }, []);

    // Runs every time "loading" status changes.
    // When "forceLogout" is set, forces logout (assuming Auth0 is finished loading, and user closed error modal)
    useEffect(() => {
        if (forceLogout && !loading && !modalShow) {
            console.log("Forcing Logout (so user is forced to use password dialog on next login attempt rather than cached cookie");
            logout();
            setForceLogout(true);
        }
    }, [loading, logout, forceLogout, modalShow]);

    // Use custom hook, essentially grabs the "loading" via useContext()
    // loading=true means that we are still waiting to get our authentication state from Auth0
    if (loading) {
        return <div>Loading... (checking for authorization)</div>;
    }

    return (
        <div>
            <HashRouter>
                <div>
                    <NavBarFn />
                    {/* A <Switch> looks through its children <Route>s and renders first that matches current URL. */}
                    {modalShow ? (<ModalFn show={modalShow} onHide={() => setModalShow(false)} message={modalMessage} title={modalTitle} />) : (<Switch>
                        <Route path="/" component={Home} />
                    </Switch>)}
                    <Route path="/privacy" component={Privacy} />
                </div>
            </HashRouter>

            {/* Create a modal, but it's likely supressed (hidden) until show variable changes to true */}
            
        </div>
    );
}
// ------------------------------------------------------------------------------------------------

function ModalFn({ message, title, ...restOfProps }) {
    // By default, "props" object is passed in, and we destructure to extract "message" and "title" properties
    return (
        <Modal {...restOfProps} className="text-center">
            {/* <Modal.Header>
                <Modal.Title className="pb-2 text-2xl">{title}</Modal.Title>
            </Modal.Header> */}
            {/* <Modal.Footer>
                <Button variant="primary" onClick={restOfProps.onHide}>
                    <img src={close} alt="close" style={{width: "3vh"}} />
                </Button>
            </Modal.Footer> */}
            <Modal.Body className="text-l">{message}</Modal.Body>
            
        </Modal>
    );
}