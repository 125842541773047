import { useEffect } from "react";
import Jhoomi from "./Jhoomi";
import Privacy from "./Privacy";
import Footer from "./Footer";
import { useContextAuth0 } from "./react-auth0-spa";
// React function component
function Home() {
  // This custom hook calls useContext() to get access to global props/methods related to our Authentication capability
  // Then use object destructuring to select item I need from the provided context
  const { isAuthenticated } = useContextAuth0();

  return (
    <div>
      {isAuthenticated ? (
        <Jhoomi />
      ) : (
        <div className="pt-10 text-center text-2xl">
          <p className="text-5xl pb-5">
            <a href="/">JHOOMI</a>
          </p>
          <p style={{ fontSize: "1.5rem" }}>
            Thank you for your interest in Jhoomi. Please sign up to receive
            further instructions to gain access to our community
          </p>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default Home;
