import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./ClaimCounter.css";

const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 40,
  strokeWidth: 2
};

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div className="time">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

let getTimeDays = (time) => (time / daySeconds) | 0;

function ClaimCounter(props) {

  let stratTime;
  let endTime;

    stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
    endTime = props.start; // use UNIX timestamp in seconds
  
 


  let remainingTime = endTime - stratTime;
  let days = Math.ceil(remainingTime / daySeconds);
  let daysDuration = days * daySeconds;

  

    return(

       
        <CountdownCircleTimer
        {...timerProps}
        colors="#7E2E84"
        duration={daysDuration}
        initialRemainingTime={remainingTime}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("", getTimeDays(daysDuration - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      
 
    );
}

export default ClaimCounter;