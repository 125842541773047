import React from "react";

// Custom Hook
// When we call "useContextAuth0()" below, essentially calls useContext(Auth0Context)
import { useContextAuth0 } from "./react-auth0-spa";

const NavBarFn = () => {
    // Calls useContext() to get access to global props/methods related to our Authentication capability
    // Then use object destructuring to select item I need from the provided context
    const { isAuthenticated, loginWithRedirect, logout } = useContextAuth0();

    // Create Navbar at top of page with Navigation Links, Login Buton, Logout Button
    // Login button calls auth0Client.loginWithRedirect()
    // In reading over that code, it is essentially just calling window.location.assign(url)
    return (
        <div className="text-right m-10">
            <span className="text-xl">Status: {isAuthenticated ? "Authenticated" : "Not Authenticated"}</span>
            <span className="ml-3 flex-right text-xl">
                {!isAuthenticated && <button className="px-2 py-1 bg-[#20248E] mx-4" onClick={() => loginWithRedirect({})}>Sign Up / Log in</button>}
                <button className="px-2 py-1 bg-[#20248E]" onClick={() => logout()}>Log out</button>
            </span>
        </div>
    );
};

export default NavBarFn;
